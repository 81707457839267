import React, { useState, useEffect } from "react";
import { FaEnvelope, FaLock, FaDownload, FaWindows, FaApple, FaLinux } from "react-icons/fa";
import { Link } from "react-router-dom";
import { call } from "../../services/api";

const Download = () => {
	const [selectedDownloadType, setSelectedDownloadType] = useState<string | null>(null);
	const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
	const [isDownloading, setIsDownloading] = useState(false);
	const [downloadError, setDownloadError] = useState<string | null>(null);
	const [os, setOs] = useState<string | null>(null);
	const [architecture, setArchitecture] = useState<string | null>(null);
	const [linuxPackageType, setLinuxPackageType] = useState<"deb" | "rpm" | null>(null);
	const [showAllDownloads, setShowAllDownloads] = useState(false);

	useEffect(() => {
		const detectMacArm = () => {
			// Check for Mac
			if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
				// Use canvas to detect ARM
				const canvas = document.createElement("canvas");
				const gl = canvas.getContext("webgl");
				if (gl) {
					const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
					if (debugInfo) {
						const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
						if (renderer.indexOf("Apple") >= 0) {
							return true;
						}
					}
				}
				// Fallback to user agent string check
				return /Mac OS X.*AppleWebKit/.test(navigator.userAgent) && !navigator.userAgent.includes("Intel");
			}
			return false;
		};

		const userAgent = navigator.userAgent;
		if (userAgent.includes("Win")) {
			setOs("Windows");
			setArchitecture(userAgent.includes("Win64") || userAgent.includes("WOW64") ? "x86_64" : "arm64");
		} else if (userAgent.includes("Mac")) {
			setOs("Mac");
			setArchitecture(detectMacArm() ? "arm64" : "x86_64");
		} else if (userAgent.includes("Linux")) {
			setOs("Linux");
			setArchitecture(userAgent.includes("x86_64") ? "x86_64" : "arm64");
			setLinuxPackageType("deb");
		}
	}, []);

	const getDownloadUrl = async (identifier: string) => {
		try {
			if (downloadUrl && selectedDownloadType === identifier) {
				window.open(downloadUrl, "_blank", "noopener,noreferrer");
				return;
			}

			setIsDownloading(true);
			setSelectedDownloadType(identifier);
			setDownloadError(null);
			const response: any = await call("download/url", "POST", { identifier });
			setDownloadUrl(response.url);
			window.open(response.url, "_blank", "noopener,noreferrer");
		} catch (err: any) {
			setDownloadError(err.message || "Failed to get download URL. Please try again.");
		} finally {
			setIsDownloading(false);
		}
	};

	const DownloadButton = ({ identifier, label }: { identifier: string; label: string }) => (
		<button
			onClick={() => getDownloadUrl(identifier)}
			className={`block w-full px-4 py-2 ${
				isDownloading ? "bg-gray-400" : "bg-gradient-to-r from-purple-600 to-pink-500 hover:from-purple-700 hover:to-pink-600"
			} text-white text-center rounded-lg transition duration-200 text-sm`}
			disabled={isDownloading}
		>
			{isDownloading ? "Downloading..." : label}
		</button>
	);

	const getSuggestedDownloadIdentifier = () => {
		if (!os || !architecture) return null;

		const identifiers: { [key: string]: { [key: string]: string } } = {
			Windows: { x86_64: "windows-all-x86_64" },
			Mac: { x86_64: "mac-all-x86_64", arm64: "mac-all-arm64" },
			Linux: {
				x86_64: linuxPackageType === "deb" ? "linux-deb-amd64" : "linux-rpm-x86_64",
				arm64: linuxPackageType === "deb" ? "linux-deb-arm64" : "linux-rpm-arm64",
			},
		};

		return identifiers[os]?.[architecture] || null;
	};

	const toggleLinuxPackageType = () => {
		setLinuxPackageType((prev) => (prev === "deb" ? "rpm" : "deb"));
	};

	const suggestedDownloadIdentifier = getSuggestedDownloadIdentifier();

	return (
		<div className={`container mx-auto p-6 ${showAllDownloads ? "max-w-4xl" : "max-w-2xl"}`}>
			<h2 className="text-3xl font-bold text-center mb-4 text-purple-600 dark:text-purple-400">Download Protocraft AI</h2>

			{os && architecture && suggestedDownloadIdentifier && !showAllDownloads ? (
				<div className="text-center mb-8">
					<h3 className="text-xl font-semibold mb-4">
						Suggested Download for You:
						<br />
						<span className="font-semibold text-lg">
							{os} ({architecture})<br />
							{os === "Linux" && ` - ${linuxPackageType?.toUpperCase()} Package`}
						</span>
					</h3>
					<div className="flex justify-center w-[50%] items-center mx-auto">
						<DownloadButton
							identifier={suggestedDownloadIdentifier}
							label={`Download for ${os} (${architecture})${os === "Linux" ? ` - ${linuxPackageType?.toUpperCase()}` : ""}`}
						/>
					</div>
					{os === "Linux" && (
						<button onClick={toggleLinuxPackageType} className="mt-2 text-purple-600 hover:text-purple-800 underline text-sm">
							Switch to {linuxPackageType === "deb" ? "RPM" : "DEB"} package
						</button>
					)}
					<button onClick={() => setShowAllDownloads(true)} className="mt-4 text-purple-600 hover:text-purple-800 underline block mx-auto text-sm">
						View other downloads
					</button>
				</div>
			) : (
				<>
					<p className="text-center my-4 text-gray-700 dark:text-gray-300">
						<strong>Note:</strong> Protocraft is currently in public alpha. The builds may be unstable. Report any issues to{" "}
						<a href="mailto:support@protocraft.ai" className="text-pink-500 hover:text-pink-600 underline">
							our support email
						</a>
						, or{" "}
						<a
							href="https://discord.gg/TTS9eeRmUy"
							target="_blank"
							rel="noopener noreferrer"
							aria-label="Discord"
							className="text-pink-500 hover:text-pink-600 underline"
						>
							in our Discord
						</a>
						.
					</p>

					<div className="mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
						<div className="w-full">
							{downloadError && (
								<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
									<strong className="font-bold">Error: </strong>
									<span className="block sm:inline">{downloadError}</span>
								</div>
							)}
							<div className="grid grid-cols-1 md:grid-cols-3 gap-6">
								<div className="bg-purple-100 dark:bg-purple-900 p-4 rounded-lg">
									<h4 className="text-xl font-semibold mb-3 flex items-center justify-center text-purple-600 dark:text-purple-300">
										<FaWindows className="mr-2 text-2xl" /> Windows
									</h4>
									<div className="space-y-2">
										<DownloadButton identifier="windows-all-x86_64" label="Intel x86_64" />
									</div>
								</div>
								<div className="bg-pink-100 dark:bg-pink-900 p-4 rounded-lg">
									<h4 className="text-xl font-semibold mb-3 flex items-center justify-center text-pink-600 dark:text-pink-300">
										<FaApple className="mr-2 text-2xl" /> Mac
									</h4>
									<div className="space-y-2">
										<DownloadButton identifier="mac-all-arm64" label="M1+ (newer Macs) (arm64)" />
										<DownloadButton identifier="mac-all-x86_64" label="Intel (older Macs) x86_64" />
									</div>
								</div>
								<div className="bg-red-100 dark:bg-red-900 p-4 rounded-lg">
									<h4 className="text-xl font-semibold mb-3 flex items-center justify-center text-red-600 dark:text-red-300">
										<FaLinux className="mr-2 text-2xl" /> Linux
									</h4>
									<div className="space-y-2">
										<h5 className="font-semibold mt-2 text-center text-gray-700 dark:text-gray-300">.deb</h5>
										<DownloadButton identifier="linux-deb-amd64" label="amd64" />
										<DownloadButton identifier="linux-deb-arm64" label="arm64" />
										<h5 className="font-semibold mt-3 text-center text-gray-700 dark:text-gray-300">.rpm</h5>
										<DownloadButton identifier="linux-rpm-x86_64" label="Intel x86_64" />
										<DownloadButton identifier="linux-rpm-arm64" label="arm64" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}

			<div className="mt-6 text-center">
				<Link to="/" className="text-purple-600 hover:text-purple-800 transition duration-200">
					← Back to Home
				</Link>
			</div>
		</div>
	);
};

export default Download;
