import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import choosingLlmImg from "../../../../images/docs/using/choosing-llm.png";
import llmFiltersImg from "../../../../images/docs/using/llm-filters.png";
import llmOptionsImg from "../../../../images/docs/using/llm-options.png";
import { Link } from "react-router-dom";

const ChoosingLLMs = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Choosing LLMs</h1>

			<p className="mb-4">
				Protocraft offers every current and major model from the following providers. Each provider is made available to you when you have provided an
				API Key for each one respectively. For more information about setting API Keys, go to{" "}
				<Link to="/docs/setting-up-api-keys" className="text-blue-500 hover:underline">
					Setting Up API Keys
				</Link>
				.
			</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>OpenAI</li>
				<li>Anthropic</li>
				<li>Google AI</li>
				<li>OpenRouter</li>
				<li>Venice AI</li>
			</ul>
			<p>As well as any custom models you may have added.</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Updates to Available LLMs</h2>
			<p className="mb-4">
				When Protocraft starts up, it checks for new models and updates its list. OpenAI, Anthropic, and Google AI model lists are updated from
				Protocraft's servers, whereas OpenRouter and Venice AI models are updated automatically directly from their respective list API endpoints.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">LLM Selection</h2>
			<ImageWithModal src={choosingLlmImg} alt="LLM Selection" className="mb-4" />
			<p className="mb-4">
				You can select an LLM from the dropdown menu located at the bottom of the chat interface. The available LLMs are grouped by provider (e.g.,
				OpenAI, Anthropic, Google AI, OpenRouter, Venice AI, and local & custom models).
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">LLM Filters</h2>
			<p className="mb-4">Protocraft provides filters to help you choose LLMs based on specific capabilities:</p>
			<ImageWithModal src={llmFiltersImg} alt="LLM Filters" className="mb-4" />
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Supports Tools:</strong> Filter for LLMs that can interact with external functions and APIs.
				</li>
				<li>
					<strong>Supports Image Vision:</strong> Filter for LLMs that can analyze and interpret images in user prompts.
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">LLM Options</h2>
			<p className="mb-4">Once you've selected an LLM, you can configure additional options to optimize its performance.</p>
			<ImageWithModal src={llmOptionsImg} alt="LLM Options" className="mb-4" />
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Temperature:</strong> This controls the randomness of the LLM's output. Lower values make the output more deterministic and focused,
					while higher values make it more creative and varied.
				</li>
				<li>
					<strong>Top P:</strong> This controls the diversity of the LLM's output. Lower values make the output more deterministic and focused, while
					higher values make it more creative and varied.
				</li>
				<li>
					<strong>Enable Cache Control (Anthropic Models Only):</strong> This option can improve response times and reduce costs for repeated queries.
					Cache write operations cost a bit more per token, but cache reads are significantly cheaper. For long-running conversations, this can be a
					good way to save costs.
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Considerations When Choosing an LLM</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Task Complexity:</strong> More advanced models (e.g., GPT-4, Claude Opus) are better suited for complex tasks, while simpler models
					may suffice for basic queries. However, the pricing can vary significantly between models.
				</li>
				<li>
					<strong>Response Speed:</strong> Newer and smaller models generally provide faster responses.
				</li>
				<li>
					<strong>Cost:</strong> More powerful models typically cost more to use. Consider your budget when selecting an LLM. Pricing information is
					listed alongside the chosen LLM.
				</li>
				<li>
					<strong>Specific Capabilities:</strong> Some models are specialized for certain tasks (e.g., image analysis, code generation). Choose based
					on your specific needs.
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Best Practices</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Start with a general-purpose model and switch to more specialized ones as needed.</li>
				<li>Experiment with different models to find the best fit for your specific use case.</li>
				<li>Keep an eye on the token usage and costs associated with each model.</li>
			</ul>
		</div>
	);
};

export default ChoosingLLMs;
