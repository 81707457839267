import React from "react";
import { Link } from "react-router-dom";
import { FaXTwitter, FaDiscord, FaTrello, FaGithub, FaYoutube } from "react-icons/fa6";

const Footer = () => {
	return (
		<footer className="bg-gradient-to-r from-purple-600 via-pink-500 to-red-500 text-white p-4 py-8 md:p-16">
			<div className="mx-auto max-w-6xl">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<nav className="mb-4 md:mb-0">
						<ul className="flex flex-wrap justify-center md:justify-start space-x-4 text-sm md:text-base">
							<li>
								<Link to="/" className="hover:text-gray-200 transition duration-200">
									Home
								</Link>
							</li>
							<li>
								<Link to="/downloads" className="hover:text-gray-200 transition duration-200">
									Download
								</Link>
							</li>
							<li>
								<Link to="/purchase" className="hover:text-gray-200 transition duration-200">
									Buy
								</Link>
							</li>
							<li>
								<Link to="/docs" className="hover:text-gray-200 transition duration-200">
									Docs
								</Link>
							</li>
							<li>
								<Link to="/faq" className="hover:text-gray-200 transition duration-200">
									FAQ
								</Link>
							</li>
							<li>
								<Link to="/account" className="hover:text-gray-200 transition duration-200">
									Account
								</Link>
							</li>
						</ul>
					</nav>
					<div className="flex space-x-4">
						<a
							href="https://x.com/protocraftai"
							target="_blank"
							rel="noopener noreferrer"
							aria-label="X (Twitter)"
							className="hover:text-gray-200 transition duration-200"
						>
							<FaXTwitter className="text-xl" />
						</a>
						<a
							href="https://discord.gg/TTS9eeRmUy"
							target="_blank"
							rel="noopener noreferrer"
							aria-label="Discord"
							className="hover:text-gray-200 transition duration-200"
						>
							<FaDiscord className="text-xl" />
						</a>
						<a
							href="https://www.youtube.com/@ProtocraftAI"
							target="_blank"
							rel="noopener noreferrer"
							aria-label="YouTube"
							className="hover:text-gray-200 transition duration-200"
						>
							<FaYoutube className="text-xl" />
						</a>
						<a
							href="https://trello.com/b/XT9hhr6i/protocraft-ai"
							target="_blank"
							rel="noopener noreferrer"
							aria-label="Trello"
							className="hover:text-gray-200 transition duration-200"
						>
							<FaTrello className="text-xl" />
						</a>
					</div>
				</div>
				<div className="mt-4 flex flex-row justify-center md:justify-start space-x-4 text-sm md:text-base">
					<Link to="/privacy-policy" className="hover:text-gray-200 transition duration-200">
						Privacy Policy
					</Link>
					<Link to="/eula" className="hover:text-gray-200 transition duration-200">
						EULA
					</Link>
				</div>
				<div className="mt-4 text-sm text-center md:text-left">&copy; {new Date().getFullYear()} Cybourgeoisie LLC. All rights reserved.</div>
			</div>
		</footer>
	);
};

export default Footer;
