import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import introImg from "../../../../images/docs/setup/intro.png";

const Introduction = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-2">What is Protocraft?</h1>

			<p className="mb-4">
				Protocraft is a powerful AI digital studio designed to enhance productivity and streamline software development workflows. It's a versatile tool
				that combines the capabilities of various AI models with a user-friendly interface, allowing creatives and developers to interact with their
				local files and codebases more efficiently.
			</p>

			<p className="mb-4">
				Key features of Protocraft include:
				<ul className="list-disc list-inside ml-4 mt-2">
					<li>Integration with multiple AI models (OpenAI, Anthropic, Google AI, OpenRouter, Venice AI, and local LLMs)</li>
					<li>Direct interaction with your local files and codebase</li>
					<li>Customizable workspaces and chat threads</li>
					<li>Built-in file browser and editor</li>
					<li>Support for image generation, LLM image vision, and analysis</li>
					<li>In-app support for RAG (Retrieval Augmented Generation) and workspace indexing</li>
					<li>Comprehensive tool system for agentic AI functionality</li>
				</ul>
			</p>

			<ImageWithModal src={introImg} alt="Protocraft Application Interface" className="mb-6" />

			<p>
				In the following sections, we'll dive deeper into how to set up and use Protocraft, explore its various features, and provide tips on how to get
				the most out of integrating it into your workflow.
			</p>
		</div>
	);
};

export default Introduction;
