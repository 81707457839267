import React from "react";
import { Link } from "react-router-dom";
import apiKeysImg from "../../../../images/docs/settings/api-keys.png";
import ImageWithModal from "../../../common/ImageWithModal";

const SettingUpAPIKeys = () => {
	const urlForKey = (key: string) => {
		if (key === "openaiKey") {
			return "https://platform.openai.com/account/api-keys";
		} else if (key === "anthropicKey") {
			return "https://console.anthropic.com/settings/keys";
		} else if (key === "googleaiKey") {
			return "https://aistudio.google.com/app/apikey";
		} else if (key === "openrouterKey") {
			return "https://openrouter.ai/settings/keys";
		} else if (key === "veniceaiKey") {
			return "https://docs.venice.ai/welcome/guides/generating-api-key";
		} else if (key === "googleSearchApiKey") {
			return "https://developers.google.com/custom-search/v1/introduction";
		} else if (key === "googleSearchEngineId") {
			return "https://cse.google.com/cse/create/new";
		} else if (key === "huggingFaceKey") {
			return "https://huggingface.co/settings/tokens";
		}
		return "";
	};

	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Setting Up API Keys</h1>

			<p>
				To use external AI services with Protocraft, you'll need to set up API keys. This page will guide you through the process of adding and managing
				your API keys.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">API Keys Settings</h2>
			<p>In the Protocraft settings, navigate to the "API Keys" section. Here, you'll see options to add keys for various services:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					OpenAI API Key -{" "}
					<a href={urlForKey("openaiKey")} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
						Get API Key
					</a>
				</li>
				<li>
					Anthropic API Key -{" "}
					<a href={urlForKey("anthropicKey")} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
						Get API Key
					</a>
				</li>
				<li>
					Google AI Gemini API Key -{" "}
					<a href={urlForKey("googleaiKey")} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
						Get API Key
					</a>
				</li>
				<li>
					OpenRouter API Key -{" "}
					<a href={urlForKey("openrouterKey")} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
						Get API Key
					</a>
				</li>
				<li>
					Venice AI API Key -{" "}
					<a href={urlForKey("veniceaiKey")} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
						Get API Key
					</a>
				</li>
				<li>
					Hugging Face API Key -{" "}
					<a href={urlForKey("huggingFaceKey")} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
						Get API Key
					</a>{" "}
					* Used for Image Generation currently
				</li>
			</ul>

			<ImageWithModal src={apiKeysImg} alt="API Keys Settings" />

			<h2 className="text-2xl font-semibold mt-8 mb-4">Adding an API Key</h2>
			<p>To add an API key:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Click on the text field next to the service you want to add a key for.</li>
				<li>Enter your API key.</li>
				<li>Click "Save" to add your keys.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Managing API Keys</h2>
			<p>You can manage your API keys as follows:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>To update a key, replace the existing key in the text field with a new one.</li>
				<li>To remove a key, delete the content of the text field or look for a "Clear" button next to the field.</li>
				<li>Click "Save" to save your changes.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Security Note</h2>
			<p>
				Protocraft stores your API keys securely on your local machine. In most cases, for most Operating Systems, the keys are stored in an encrypted
				file, encrypted by your local machine. In some cases on Windows, Protocraft does not have the permission or ability to encrypt your keys, and
				they are stored in an unencrypted config file if and only if encryption is unavailable for Protocraft. Your keys are are not sent to any
				external servers (besides the AI providers that you are using for verification). Always be cautious and never share your API keys with others.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Next Steps</h2>
			<p>
				Once you've set up your API keys, you're ready to start using external AI services with Protocraft. If you prefer to use a local LLM instead,
				check out our guide on{" "}
				<Link to="/docs/setting-up-local-llm" className="text-blue-500 hover:underline">
					Setting Up a Local LLM
				</Link>
				.
			</p>
		</div>
	);
};

export default SettingUpAPIKeys;
